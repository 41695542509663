@font-face {
    font-family: 'Gotham Black';
    src: url('../fonts/GothamBlack.woff2') format('woff2'),
        url('../fonts/GothamBlack.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham Bold';
    src: url('../fonts/Gotham-Bold.woff2') format('woff2'),
        url('../fonts/Gotham-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham Book';
    src: url('../fonts/Gotham-Book.woff2') format('woff2'),
        url('../fonts/Gotham-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham Medium';
    src: url('../fonts/Gotham-Medium.woff2') format('woff2'),
        url('../fonts/Gotham-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham Light';
    src: url('../fonts/Gotham-Light.woff2') format('woff2'),
        url('../fonts/Gotham-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham Narrow Black';
    src: url('../fonts/GothamNarrow-Black.woff2') format('woff2'),
        url('../fonts/GothamNarrow-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham Narrow Book';
    src: url('../fonts/GothamNarrow-Book.woff2') format('woff2'),
        url('../fonts/GothamNarrow-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham Narrow Ultra';
    src: url('../fonts/GothamNarrow-Ultra.woff2') format('woff2'),
        url('../fonts/GothamNarrow-Ultra.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham Narrow';
    src: url('../fonts/GothamNarrow-Thin.woff2') format('woff2'),
        url('../fonts/GothamNarrow-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham Ultra';
    src: url('../fonts/Gotham-Ultra.woff2') format('woff2'),
        url('../fonts/Gotham-Ultra.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham Thin';
    src: url('../fonts/Gotham-Thin.woff2') format('woff2'),
        url('../fonts/Gotham-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}
