@font-face {
  font-family: Gotham Black;
  src: url("GothamBlack.8d059a5b.woff2") format("woff2"), url("GothamBlack.3f560b8c.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Gotham Bold;
  src: url("Gotham-Bold.02ed9471.woff2") format("woff2"), url("Gotham-Bold.56860583.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Gotham Book;
  src: url("Gotham-Book.0ba57d5c.woff2") format("woff2"), url("Gotham-Book.1ba81169.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Gotham Medium;
  src: url("Gotham-Medium.1cec6a8a.woff2") format("woff2"), url("Gotham-Medium.e37182ae.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Gotham Light;
  src: url("Gotham-Light.ba8f80c8.woff2") format("woff2"), url("Gotham-Light.447ec5a1.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Gotham Narrow Black;
  src: url("GothamNarrow-Black.c469d509.woff2") format("woff2"), url("GothamNarrow-Black.411fd680.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Gotham Narrow Book;
  src: url("GothamNarrow-Book.be653536.woff2") format("woff2"), url("GothamNarrow-Book.38e2c5e1.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Gotham Narrow Ultra;
  src: url("GothamNarrow-Ultra.7b9a8bbe.woff2") format("woff2"), url("GothamNarrow-Ultra.1c442482.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Gotham Narrow;
  src: url("GothamNarrow-Thin.d7940dd3.woff2") format("woff2"), url("GothamNarrow-Thin.e766e50e.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Gotham Ultra;
  src: url("Gotham-Ultra.c0554d42.woff2") format("woff2"), url("Gotham-Ultra.e679c066.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Gotham Thin;
  src: url("Gotham-Thin.5fc29a02.woff2") format("woff2"), url("Gotham-Thin.d7b3e261.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
/*# sourceMappingURL=fonts.74c41c23.css.map */
